import { useEffect, useState } from 'react';
import {
  Box,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moduleService from '../../application/services/ModuleService';
import { Module } from '../../domain/entities/Module';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import http from '../../infrastructure/api/http';

import ICON_SETTINGS from '../../shared/assets/menu/icon_settings.png';
import ICON_REMOVE from '../../shared/assets/menu/icon_remove.png';
import ICON_PLUS from '../../shared/assets/menu/icon_plus.png';
import CreateIcon from '@mui/icons-material/Create';
import ModuleForm from '../components/ModuleForm';

const RootAdminCenterModules = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [modules, setModules] = useState<Module[]>([]);
  const [moduleToEdit, setModuleToEdit] = useState<null | Module>(null);
  let { customerId, centerId } = useParams();
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const navigate = useNavigate();

  const loadData = async (centerId: string) => {
    try {
      setIsLoading(true);
      const modules = await moduleService.loadAllModulesByCenterId(
        `${centerId}`
      );
      setModules(modules);
    } catch (error) {
      alert(`Error al cargar los módulos`);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (centerId) {
      loadData(`${centerId}`);
    }
  }, [centerId]);

  return (
    <Box component="div">
      <Grid container spacing={2}>
        <Grid item xs={9} sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            variant="h5"
            fontSize={isSmallScreen ? '20px' : undefined}
          >
            Administrar Módulos
          </Typography>
        </Grid>
        <Grid
          item
          xs={3}
          display="flex"
          justifyContent="flex-end"
          alignContent="end"
        >
          <IconButton
            color="primary"
            aria-label="add"
            onClick={() => {
              //navigate(`/admin/customers/add`);
              setShowForm(true);
            }}
          >
            <img src={ICON_PLUS} alt="Nuevo cliente" width={48} />
          </IconButton>
        </Grid>
        <Grid item xs={12}>
          <TableContainer>
            <Table
              sx={{
                backgroundColor: 'transparent',
                border: 0,
                width: isSmallScreen ? '100%' : '50%',
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell style={{ borderBottomColor: 'transparent' }}>
                    <Typography fontWeight={'600'} align="center">
                      Módulo
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottomColor: 'transparent' }}>
                    <Typography fontWeight={'600'} align="center">
                      No. Jaulas
                    </Typography>
                  </TableCell>
                  <TableCell style={{ borderBottomColor: 'transparent' }}>
                    <Typography fontWeight={'600'} align="center">
                      Acción
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoading && (
                  <TableRow
                    sx={{
                      'td, th': { border: 0 },
                    }}
                  >
                    <TableCell colSpan={4}>
                      <Box
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 15,
                        }}
                      >
                        <CircularProgress />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
                {modules.map((module) => (
                  <TableRow
                    key={module.id}
                    sx={{
                      'td, th': { border: 0 },
                    }}
                  >
                    <TableCell sx={{ p: 1 }}>
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 10,
                          alignItems: 'center',
                          justifyContent: 'center',
                        }}
                      >
                        {module.name}
                      </Box>
                    </TableCell>

                    <TableCell align="center">{module.countJails}</TableCell>

                    <TableCell align="center" sx={{ padding: 1 }}>
                      <IconButton
                        aria-label="show"
                        onClick={() => {
                          setModuleToEdit(module);
                          setShowForm(true);
                        }}
                      >
                        <CreateIcon style={{ fontSize: '14px' }} />
                      </IconButton>
                      <IconButton
                        aria-label="show"
                        onClick={() => {
                          navigate(`${module.id}/jails`);
                        }}
                      >
                        <img
                          src={ICON_SETTINGS}
                          alt="Configuración"
                          width={14}
                        />
                      </IconButton>
                      <IconButton
                        aria-label="show"
                        onClick={async () => {
                          if (
                            window.confirm(
                              '¿Está seguro que desea eliminar este módulo?'
                            )
                          ) {
                            try {
                              await http.delete(
                                `/centers/${centerId}/modules/${module.id}`
                              );
                              if (centerId) loadData(centerId);
                            } catch (error) {
                              alert(
                                'Se ha producido un error al remover el módulo'
                              );
                            }
                          }
                        }}
                      >
                        <img src={ICON_REMOVE} alt="Eliminar" width={14} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      <Modal
        open={showForm}
        onClose={() => {
          setShowForm(false);
          setModuleToEdit(null);
        }}
      >
        <Box
          sx={{
            position: 'absolute' as 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
          }}
        >
          {centerId && (
            <ModuleForm
              centerId={parseInt(centerId)}
              module={moduleToEdit}
              onCreated={() => {
                if (centerId) loadData(centerId);
                setShowForm(false);
                setModuleToEdit(null);
              }}
              onCancel={() => {
                setShowForm(false);
                setModuleToEdit(null);
              }}
            />
          )}
        </Box>
      </Modal>
    </Box>
  );
};

export default RootAdminCenterModules;
