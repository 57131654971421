import { Center } from '../../domain/entities/Center';
import { RestApiCenterRepository } from '../../infrastructure/repositories/RestApiCenterRepository';

export class CenterService {
  private restApiCenterRepository = new RestApiCenterRepository();

  async loadCentersByCustomerId(customerId: string): Promise<Center[]> {
    return this.restApiCenterRepository.findCentersByCustomerId(customerId);
  }

  async loadAllCentersByCustomerId(customerId: string): Promise<Center[]> {
    return this.restApiCenterRepository.findAllCentersByCustomerId(customerId);
  }

  async loadAllCentersByCustomerIdAndUserId(
    customerId: string
  ): Promise<Center[]> {
    return this.restApiCenterRepository.findAllCentersByCustomerIdAndUserId(
      customerId
    );
  }

  async create(center: Center): Promise<Center> {
    return this.restApiCenterRepository.create(center);
  }

  async update(center: Center): Promise<Center> {
    return this.restApiCenterRepository.update(center);
  }
}

const instance = new CenterService();

export default instance;
