import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import 'moment/locale/es';
import 'moment-timezone';
import { Customer } from '../../domain/entities/Customer';
import { LampStatus } from '../../shared/enums/lamp-status.enum';
import { useSelector } from 'react-redux';
import { selectUser } from '../../domain/store/session/selectors';
import http from '../../infrastructure/api/http';
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

moment.tz.setDefault('America/Santiago');

type Props = {
  onSelectCenter?: (center: any) => void;
  customerId: number;
};

type Lamp = {
  id: number;
  document: string;
  amperageToride: string;
  realStatus: LampStatus;
  createdAt: string;
  updatedAt: string;
};

type Jail = {
  name: string;
  createdAt: string;
  updatedAt: string;
  id: number;
  alarms: number;
  alerts: number;
  lamps: Lamp[];
};

type Statuses = {
  id: number;
  createdAt: string;
  d: number;
  f: number;
  fa: number;
  fb: number;
  fc: number;
  ia: number;
  ib: number;
  ic: number;
  updatedAt: string;
  va: number;
  vb: number;
  vc: number;
};

type Center = {
  id: number;
  name: string;
  statuses: Statuses[];
  jails: Jail[];
  customer: Customer;
};

const calculeLastModified = (center: any) => {
  let result = 'Sin datos';

  if (center.statuses.length > 0) {
    if (center.statuses[center.statuses.length - 1].updatedAt) {
      result = center.statuses[center.statuses.length - 1].updatedAt;
    } else {
      result = center.statuses[center.statuses.length - 1].createdAt;
    }
  }

  if (moment(result).isValid()) {
    const now = moment().tz('America/Santiago');
    const startDate = moment(result).tz('America/Santiago');

    // Calcular la diferencia de tiempo
    const timeDiff = moment.duration(now.diff(startDate));

    // Obtener los componentes de tiempo individuales
    const years = timeDiff.years();
    const months = timeDiff.months();
    const weeks = timeDiff.weeks();
    const days = timeDiff.days();
    const hours = timeDiff.hours();
    const minutes = timeDiff.minutes();
    const seconds = timeDiff.seconds();

    if (years > 0) {
      return `hace ${years} año(s)`;
    } else if (months > 0) {
      return `hace ${months} mes(es)`;
    } else if (weeks > 0) {
      return `hace ${weeks} semana(s)`;
    } else if (days > 0) {
      return `hace ${days} dia(s)`;
    } else if (hours > 0) {
      return `hace ${hours} hora(s)`;
    } else if (minutes > 0) {
      return `hace ${minutes} minuto(s)`;
    } else {
      return `hace ${seconds} segundo(s)`;
    }
  }

  return result;
};

const DashboardManagerMobile: FC<Props> = ({ onSelectCenter, customerId }) => {
  const currentUser = useSelector(selectUser);
  const [centers, setCenters] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [expanded, setExpanded] = useState(-1);

  useEffect(() => {
    if (currentUser) {
      (async () => {
        setCenters([]);
        setIsLoading(true);
        try {
          const response = await http.get<any[]>(
            `/customers/${customerId}/centers/details`
          );
          setCenters(response.data);
        } catch (error: any) {
          alert(error.message);
        } finally {
          setIsLoading(false);
        }
      })();
    }
  }, [currentUser, customerId]);
  return (
    <Stack spacing={2}>
      {isLoading && (
        <CircularProgress
          sx={{ alignSelf: 'center' }}
          size={14}
          color="inherit"
        />
      )}
      {centers.map((center: any) => (
        <Card sx={{ p: '5px' }} key={center.id}>
          <Stack>
            <Box
              display="flex"
              flexDirection="row"
              alignContent={'center'}
              alignItems={'center'}
              width={'100%'}
            >
              <Typography variant="body1" flex={1} fontWeight={600}>
                {center.customer.name}
              </Typography>
              <IconButton
                sx={{ alignSelf: 'end' }}
                size="small"
                onClick={() => {
                  setExpanded(expanded === center.id ? -1 : center.id);
                }}
              >
                <KeyboardArrowDownIcon />
              </IconButton>
            </Box>
            <Divider />
            <Box display="flex" flexDirection={'row'} gap={1}>
              <Typography variant="body1" fontWeight={600}>
                Centro
              </Typography>
              <Typography
                variant="body1"
                sx={{ color: '#4DADBC', cursor: 'pointer' }}
                onClick={() => {
									if(!currentUser?.isElectricOnly)
                    onSelectCenter?.(center);
                }}
              >
                {center.name}
              </Typography>
            </Box>
            <Collapse in={expanded === center.id} timeout="auto">
              <List disablePadding>
                {!currentUser?.isElectricOnly && (
                  <ListItem
                    sx={{ p: '5px' }}
                    secondaryAction={
                      <div
                        style={{
                          backgroundColor:
                            center.jails.filter((j: any) => j.status).length > 0
                              ? Math.round(
                                  (center.jails.filter((j: any) => j.status).reduce(
                                    (acc: any, curr: any) =>
                                      acc +
                                      curr.lamps.filter((lamp: any) => {
																				if(!lamp.status) return false;
                                        const configLamp =
                                          currentUser?.configs.filter(
                                            (config) =>
                                              config.centerId === center.id &&
                                              config.variableId === 1 &&
                                              config.status
                                          );
                                        if (
                                          configLamp &&
                                          configLamp.length > 0
                                        ) {
                                          const valueOperation =
                                            configLamp[0].multiplier *
                                            parseFloat(lamp.amperageToroide);
                                          if (
                                            valueOperation >
                                              configLamp[0].max ||
                                            valueOperation < configLamp[0].min
                                          )
                                            return false;
                                        }
                                        return (
                                          lamp.realStatus === LampStatus.ONLINE
                                        );
                                      }).length,
                                    0
                                  ) /
                                    center.jails.filter((j: any) => j.status).reduce(
                                      (acc: any, curr: any) =>
                                        acc + curr.lamps.filter((l: any) => l.status).length,
                                      0
                                    )) *
                                    100
                                ) <= 90
                                ? '#F8D900'
                                : '#028727'
                              : '#ddd',
                          color: '#000',
                          display: 'flex',
                          justifyContent: 'center',
                          padding: 1,
                          fontWeight: '600',
                          width: '60px',
                        }}
                      >
                        {center.jails.filter((j: any) => j.status).length > 0 ? (
                          <>
                            {Math.round(
                              (center.jails.filter((j: any) => j.status).reduce(
                                (acc: any, curr: any) =>
                                  acc +
                                  curr.lamps.filter((lamp: any) => {
																		if(!lamp.status) return false;
                                    const configLamp =
                                      currentUser?.configs.filter(
                                        (config) =>
                                          config.centerId === center.id &&
                                          config.variableId === 1 &&
                                          config.status
                                      );
                                    if (configLamp && configLamp.length > 0) {
                                      const valueOperation =
                                        configLamp[0].multiplier *
                                        parseFloat(lamp.amperageToroide);
                                      if (
                                        valueOperation > configLamp[0].max ||
                                        valueOperation < configLamp[0].min
                                      )
                                        return false;
                                    }
                                    return (
                                      lamp.realStatus === LampStatus.ONLINE
                                    );
                                  }).length,
                                0
                              ) /
                                center.jails.filter((j: any) => j.status).reduce(
                                  (acc: any, curr: any) =>
                                    acc + curr.lamps.filter((l: any) => l.status).length,
                                  0
                                )) *
                                100
                            )}
                          </>
                        ) : (
                          <>-</>
                        )}
                      </div>
                    }
                  >
                    <ListItemText>
                      <Typography variant="body1">Estado</Typography>
                    </ListItemText>
                  </ListItem>
                )}
                {!currentUser?.isElectricOnly && (
                  <ListItem
                    sx={{ p: '5px' }}
                    secondaryAction={
                      <>
                        {center.jails.filter((j: any) => j.status).reduce(
                          (acc: any, curr: any) => acc + curr.lamps.filter((l: any) => l.status).length,
                          0
                        )}
                      </>
                    }
                  >
                    <ListItemText>
                      <Typography variant="body1">No lámparas</Typography>
                    </ListItemText>
                  </ListItem>
                )}
                {!currentUser?.isElectricOnly && (
                  <ListItem
                    sx={{ p: '5px' }}
                    secondaryAction={<>{center.jails.filter((j: any) => j.status).length}</>}
                  >
                    <ListItemText>
                      <Typography variant="body1">No jaulas</Typography>
                    </ListItemText>
                  </ListItem>
                )}
                {!currentUser?.isElectricOnly && (
                  <ListItem
                    sx={{ p: '5px' }}
                    secondaryAction={
                      <>
                        {center.jails.filter((j: any) => j.status).reduce(
                          (acc: any, curr: any) =>
                            acc +
                            curr.lamps.filter((lamp: any) => {
															if(!lamp.status) return false;
                              const configLamp = currentUser?.configs.filter(
                                (config) =>
                                  config.centerId === center.id &&
                                  config.variableId === 1 &&
                                  config.status
                              );
                              if (configLamp && configLamp.length > 0) {
                                const valueOperation =
                                  configLamp[0].multiplier *
                                  parseFloat(lamp.amperageToroide);
                                if (
                                  valueOperation > configLamp[0].max ||
                                  valueOperation < configLamp[0].min
                                )
                                  return false;
                              }
                              return lamp.realStatus === LampStatus.ONLINE;
                            }).length,
                          0
                        )}
                      </>
                    }
                  >
                    <ListItemText>
                      <Typography variant="body1">Lámparas ON</Typography>
                    </ListItemText>
                  </ListItem>
                )}
                {!currentUser?.isElectricOnly && (
                  <ListItem
                    sx={{ p: '5px' }}
                    secondaryAction={
                      <>
                        {center.jails.filter((j: any) => j.status).reduce(
                          (acc: any, curr: any) =>
                            acc +
                            curr.lamps.filter((lamp: any) => {
															if(!lamp.status) return false;
                              const configLamp = currentUser?.configs.filter(
                                (config) =>
                                  config.centerId === center.id &&
                                  config.variableId === 1 &&
                                  config.status
                              );
                              if (configLamp && configLamp.length > 0) {
                                const valueOperation =
                                  configLamp[0].multiplier *
                                  parseFloat(lamp.amperageToroide);
                                if (
                                  valueOperation > configLamp[0].max ||
                                  valueOperation < configLamp[0].min
                                )
                                  return true;
                              }
                              return lamp.realStatus !== LampStatus.ONLINE;
                            }).length,
                          0
                        )}
                      </>
                    }
                  >
                    <ListItemText>
                      <Typography variant="body1">Lámparas OFF</Typography>
                    </ListItemText>
                  </ListItem>
                )}
                <ListItem
                  sx={{ p: '5px' }}
                  secondaryAction={
                    <>
                      {center.statuses.length > 0 ? (
                        <>
                          {Math.round(
                            ((center.statuses[0].vfa * center.statuses[0].cfa +
                              center.statuses[0].vfb * center.statuses[0].cfb +
                              center.statuses[0].vfc * center.statuses[0].cfc) /
                              1000) *
                              100
                          ) / 100}{' '}
                          kW
                        </>
                      ) : (
                        <> - </>
                      )}
                    </>
                  }
                >
                  <ListItemText>
                    <Typography variant="body1">Potencia</Typography>
                  </ListItemText>
                </ListItem>
                <ListItem
                  sx={{ p: '5px' }}
                  secondaryAction={<>{calculeLastModified(center)}</>}
                >
                  <ListItemText>
                    <Typography variant="body1">Actualizado</Typography>
                  </ListItemText>
                </ListItem>
              </List>
            </Collapse>
          </Stack>
        </Card>
      ))}
    </Stack>
  );
};

export default DashboardManagerMobile;
