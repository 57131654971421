import {
  Alert,
  Box,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import { useSelector } from 'react-redux';
import { selectIsSmallScreen } from '../../domain/store/ui/selectors';
import { useEffect, useState } from 'react';
import { Status } from '../../domain/entities/Status';
import Chart from 'react-google-charts';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import CircleProgress from '../components/CircleProgress';
import http from '../../infrastructure/api/http';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { selectUser } from '../../domain/store/session/selectors';
import { useNavigate } from 'react-router-dom';
import ICON_CONFIG from '../../shared/assets/menu/icon_zoom.png';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import CloseIcon from '@mui/icons-material/Close';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale, // Para el eje X
  LinearScale, // Para el eje Y (escala lineal)
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Aquí registras los componentes de Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

dayjs.locale('es');

function openFullscreen() {
  const elem: any = document.documentElement;
  if (elem.requestFullscreen) {
    elem.requestFullscreen();
  } else if (elem.webkitRequestFullscreen) {
    /* Safari */
    elem.webkitRequestFullscreen();
  } else if (elem.msRequestFullscreen) {
    /* IE11 */
    elem.msRequestFullscreen();
  }
}

const ZoomWrapper = styled('div')<{ zoom: number }>`
  width: calc(100% / ${(props) => props.zoom / 100});
  height: calc(100% / ${(props) => props.zoom / 100});
  transform: scale(${(props) => props.zoom / 100});
  transform-origin: 0 0;
  overflow: hidden;
`;

const Dashboard = () => {
  const [loading, setLoading] = useState(true);
  const [status, setStatus] = useState<Status | null>(null);
  const [reportTime, setReportTime] = useState<'24hrs' | 'week' | 'month'>(
    'month'
  );
  const [allStatus, setAllStatus] = useState<Status[]>([]);
  const [powerDemandReport, setPowerDemandReport] = useState<
    Array<{
      periodo: string;
      demanda_potencia: number;
      pafa: number;
      pafb: number;
      pafc: number;
    }>
  >([]);
  const [activeEnergy, setActiveEnergy] = useState<{
    fase_r: number;
    fase_s: number;
    fase_t: number;
  }>({
    fase_r: 0,
    fase_s: 0,
    fase_t: 0,
  });
  const [reactiveEnergy, setReActiveEnergy] = useState<{
    fase_r: number;
    fase_s: number;
    fase_t: number;
  }>({
    fase_r: 0,
    fase_s: 0,
    fase_t: 0,
  });
  const [consumptionReportMonth, setConsumptionReportMonth] = useState<
    Array<{
      fecha: string;
      acumulado_eadi: number;
    }>
  >([]);
  const [consumptionReportYear, setConsumptionReportYear] = useState<
    Array<{
      mes: string;
      acumulado_eadi: number;
    }>
  >([]);
  const isSmallScreen = useSelector(selectIsSmallScreen);
  const currentUser = useSelector(selectUser);
  const navigate = useNavigate();
  const [showConfigPage, setShowConfigPage] = useState(false);
  const [zoom, setZoom] = useState(100);
  const [collapse, setCollapse] = useState({
    power_demand: false,
    consume_year: false,
  });

  const loadAR = async () => {
    try {
      const statusReq = await http.get(`/center-status`);
      setAllStatus(statusReq.data);
      if (statusReq.data.length > 0) {
        setStatus(statusReq.data[0]);
      }
    } catch (error) {
      alert('Se ha producido un error al cargar el dashboard');
    } finally {
      setLoading(false);
    }
  };

  const loadReports = async (centerStatusId: number, typeReport: string) => {
    try {
      setPowerDemandReport([
        { demanda_potencia: 0, pafa: 0, pafb: 0, pafc: 0, periodo: '' },
      ]);
      setActiveEnergy({
        fase_r: 0,
        fase_s: 0,
        fase_t: 0,
      });
      setReActiveEnergy({
        fase_r: 0,
        fase_s: 0,
        fase_t: 0,
      });
      setConsumptionReportMonth([{ fecha: '', acumulado_eadi: 0 }]);
      setConsumptionReportYear([{ mes: '', acumulado_eadi: 0 }]);

      const statusReq = await http.get(
        `/center-status/${centerStatusId}/${typeReport}`
      );

      setPowerDemandReport(statusReq.data.power_demand);
      setActiveEnergy(statusReq.data.monthly_active_energy);
      setReActiveEnergy(statusReq.data.monthly_reactive_energy);
      setConsumptionReportMonth(statusReq.data.consumption_report_month);
      setConsumptionReportYear(statusReq.data.consumption_report_year);
    } catch (error) {
      alert('Error al cargar los datos del reporte');
    }
  };

  useEffect(() => {
    loadAR();
    /* const interval = setInterval(() => {
      loadAR();
    }, 60000);

    return () => {
      clearInterval(interval);
    }; */
  }, []);

  useEffect(() => {
    if (status) {
      loadReports(status.id, reportTime);

      const interval = setInterval(() => {
        loadReports(status.id, reportTime);
      }, 60000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [status, reportTime]);

  useEffect(() => {
    if (currentUser && !currentUser.useDashboard) {
      navigate('/');
    }
  }, [currentUser]);

  if (loading)
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginTop={5}
      >
        <CircularProgress />
      </Box>
    );

  if (status === null)
    return (
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="center"
        marginTop={5}
      >
        <Alert severity="error">No hay un Analizador de red disponible</Alert>
      </Box>
    );

  return (
    <>
      {!isSmallScreen && (
        <Box
          position="fixed"
          top={showConfigPage ? '0' : '-200px'}
          right="0"
          sx={{
            transition: 'all 0.3s ease-in-out',
            bgcolor: 'background.default',
            borderBottomLeftRadius: '5px',
            p: '5px',
          }}
          zIndex={500}
        >
          <Stack direction="row" gap={1} sx={{ position: 'relative' }}>
            <IconButton
              onClick={() => {
                setZoom(zoom + 2);
              }}
            >
              <AddIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                setZoom(zoom - 2);
              }}
            >
              <RemoveIcon />
            </IconButton>
            <IconButton
              onClick={() => {
                openFullscreen();
              }}
            >
              <FullscreenIcon />
            </IconButton>
            <Box
              position="absolute"
              bottom="-30px"
              width="100%"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <Box
                padding="2px"
                sx={{
                  cursor: 'pointer',
                  bgcolor: 'background.default',
                  borderBottomLeftRadius: '5px',
                  borderBottomRightRadius: '5px',
                }}
                onClick={() => {
                  setShowConfigPage(false);
                }}
              >
                <CloseIcon />
              </Box>
            </Box>
          </Stack>
        </Box>
      )}
      <ZoomWrapper zoom={zoom}>
        <Box
          component="div"
          height="100%"
          sx={{
            overflowY: isSmallScreen ? 'auto' : 'hidden',
            overflowX: 'hidden',
          }}
        >
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <Grid container spacing={2}>
              <Grid
                item
                xs={12}
                style={{
                  display: 'flex',
                  justifyContent: 'end',
                  paddingTop: '25px',
                }}
              >
                {!isSmallScreen && (
                  <IconButton
                    sx={{ p: 0, mr: 1 }}
                    onClick={() => {
                      setShowConfigPage(true);
                    }}
                  >
                    <img src={ICON_CONFIG} alt="Zoom" width="32" />
                  </IconButton>
                )}
                <FormControl>
                  <InputLabel>Analizador de RED</InputLabel>
                  <Select
                    style={{ width: '300px' }}
                    labelId="selAre"
                    label="Analizador de RED"
                    autoWidth
                    value={status?.id ?? undefined}
                    onChange={(statusId) => {
                      const st = allStatus.find(
                        (st) => `${st.id}` === `${statusId.target.value}`
                      );
                      if (st) setStatus(st);
                    }}
                  >
                    {allStatus.map((st) => (
                      <MenuItem key={st.id} value={st.id}>
                        {st.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>

              <Grid
                item
                xs={12}
                sx={{
                  marginTop: '25px',
                  px: 5,
                  paddingRight: isSmallScreen ? '0' : undefined,
                }}
              >
                <Grid container>
                  {/* Graficos de gauge */}
                  <Grid
                    item
                    sx={{ border: 1, p: 2, borderColor: '#2c3e50' }}
                    xs={12}
                    md={6}
                  >
                    <Grid container spacing={isSmallScreen ? 2 : 0}>
                      <Grid item xs={12} sm={12} md={6} xl={4}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="start"
                          gap={1}
                        >
                          <Box
                            bgcolor="green"
                            width={10}
                            height={10}
                            borderRadius="50%"
                          ></Box>
                          <Typography fontWeight="700">Consumo</Typography>
                          <Typography color="#ddd">| Tiempo real</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12} sm={12} md={6} xl={8}>
                        <Typography fontWeight={'700'}>
                          {status?.title}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} height={50}></Grid>
                      <Grid item xs={12} md={4}>
                        <CircleProgress
                          progress={80}
                          label={
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography fontWeight={'500'}>
                                {status?.vfa} V
                              </Typography>
                              <Typography>{Math.abs(status?.pafa).toFixed(2)} kW</Typography>
                              <Typography fontWeight={'700'} marginTop={5}>
                                FASE R
                              </Typography>
                            </Box>
                          }
                          color="#27ae60"
                          size={170}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CircleProgress
                          progress={80}
                          label={
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography fontWeight={'500'}>
                                {status?.vfb} V
                              </Typography>
                              <Typography>{Math.abs(status?.pafb).toFixed(2)} kW</Typography>
                              <Typography fontWeight={'700'} marginTop={5}>
                                FASE S
                              </Typography>
                            </Box>
                          }
                          color="#c0392b"
                          size={170}
                        />
                      </Grid>
                      <Grid item xs={12} md={4}>
                        <CircleProgress
                          progress={80}
                          label={
                            <Box
                              display="flex"
                              flexDirection="column"
                              justifyContent="center"
                              alignItems="center"
                            >
                              <Typography fontWeight={'500'}>
                                {status?.vfc} V
                              </Typography>
                              <Typography>{Math.abs(status?.pafc).toFixed(2)} kW</Typography>
                              <Typography fontWeight={'700'} marginTop={5}>
                                FASE T
                              </Typography>
                            </Box>
                          }
                          color="#f39c12"
                          size={170}
                        />
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                        marginTop={5}
                      >
                        <Typography>
                          Última lectura:{' '}
                          {dayjs(status?.updatedAt).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Tabla */}
                  <Grid
                    item
                    sx={{ border: 1, p: 2, borderColor: '#2c3e50' }}
                    xs={12}
                    md={6}
                  >
                    <Grid container gap={2}>
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="start"
                          gap={1}
                        >
                          <Box
                            bgcolor="green"
                            width={10}
                            height={10}
                            borderRadius="50%"
                          ></Box>
                          <Typography fontWeight="700">Consumo</Typography>
                          <Typography color="#ddd">| Tiempo real</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <TableContainer sx={{ borderRadius: '15px' }}>
                          <Table
                            sx={{
                              minWidth: 550,
                              backgroundColor: 'rgba(0,0,0,0.09)',
                            }}
                            aria-label="simple table"
                          >
                            <TableHead>
                              <TableRow sx={{ backgroundColor: '#212630' }}>
                                <TableCell
                                  width={140}
                                  padding="none"
                                  sx={{ padding: 2.5 }}
                                ></TableCell>
                                <TableCell
                                  width={80}
                                  align="center"
                                  padding="none"
                                >
                                  Fase R
                                </TableCell>
                                <TableCell
                                  width={80}
                                  align="center"
                                  padding="none"
                                >
                                  Fase S
                                </TableCell>
                                <TableCell
                                  width={80}
                                  align="center"
                                  padding="none"
                                >
                                  Fase T
                                </TableCell>
                                <TableCell
                                  width={80}
                                  align="center"
                                  padding="none"
                                >
                                  Total
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  sx={{ padding: 1 }}
                                >
                                  Potencia activa
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {Math.abs(status?.pafa).toFixed(2)} kW
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {Math.abs(status?.pafb).toFixed(2)} kW
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {Math.abs(status?.pafc).toFixed(2)} kW
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {(Math.abs(status?.pafa) + Math.abs(status?.pafb) + Math.abs(status?.pafc)).toFixed(2)} kW
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  sx={{ padding: 1 }}
                                >
                                  Potencia reactiva
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {Math.abs(status?.prfa).toFixed(2)} kVAR
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {Math.abs(status?.prfb).toFixed(2)} kVAR
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {Math.abs(status?.prfc).toFixed(2)} kVAR
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {(Math.abs(status?.prfa) + Math.abs(status?.prfb) + Math.abs(status?.prfc)).toFixed(2)} kVAR
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  sx={{ padding: 1 }}
                                >
                                  Tensión
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {status?.vfa} V
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {status?.vfb} V
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {status?.vfc} V
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {' '}
                                  -{' '}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  sx={{ padding: 1 }}
                                >
                                  Corriente
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {status?.cfa} A
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {status?.cfb} A
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {status?.cfc} A
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {' '}
                                  -{' '}
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  '&:last-child td, &:last-child th': {
                                    border: 0,
                                  },
                                }}
                              >
                                <TableCell
                                  component="th"
                                  scope="row"
                                  padding="none"
                                  sx={{ padding: 1 }}
                                >
                                  Factor de potencia
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {status?.fpfa}
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {status?.fpfb}
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {status?.fpfc}
                                </TableCell>
                                <TableCell align="center" padding="none">
                                  {status?.fpt}
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        display={'flex'}
                        alignItems={'center'}
                        justifyContent={'center'}
                      >
                        <Typography>
                          Última lectura:{' '}
                          {dayjs(status?.updatedAt).format(
                            'YYYY-MM-DD HH:mm:ss'
                          )}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Gráfica de consumo x mes */}
                  <Grid
                    item
                    sx={{ border: 1, p: 2, borderColor: '#2c3e50' }}
                    xs={12}
                    md={6}
                  >
                    <Grid container gap={1}>
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="start"
                          gap={1}
                        >
                          <Typography fontWeight="700">Consumo</Typography>
                          <Typography color="#ddd">| Este mes</Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Chart
                          chartType="ColumnChart"
                          width="100%"
                          height="300px"
                          data={[
                            ['Día', 'Consumo'],
                            ...consumptionReportMonth.map((crm) => {
                              return [
                                crm.fecha,
                                parseFloat(crm.acumulado_eadi.toFixed(2)),
                              ];
                            }),
                          ]}
                          options={{
                            vAxis: {
                              title: 'Potencia activa total (MkW)',
                              titleTextStyle: { color: '#FFF' }, // Cambia el color del título del eje vertical a blanco
                              textStyle: { color: '#FFF' }, // Cambia el color del texto del eje vertical a blanco
                              minValue: 0, // Hace que el eje vertical comience desde 0
                            },
                            hAxis: {
                              textStyle: { color: '#FFF', fontSize: 10 }, // Cambia el color del texto del eje horizontal a blanco
                              slantedText: true,
                              slantedTextAngle: 45,
                            },
                            legend: 'none',
                            backgroundColor: 'transparent',
                            chartArea: {
                              left: 70, // Ajusta el margen izquierdo
                              right: 20, // Ajusta el margen derecho
                              top: 50, // Ajusta el margen superior
                              bottom: 50, // Ajusta el margen inferior
                            },
                            colors: ['#27ae60'],
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Gráfica de tortas */}
                  <Grid item xs={12} md={6}>
                    <Grid container>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ border: 1, p: 2, borderColor: '#2c3e50' }}
                      >
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="start"
                            flexWrap="wrap"
                            gap={1}
                          >
                            <Typography fontWeight="700">
                              ENERGÍA ACTIVA MENSUAL
                            </Typography>
                            <Typography color="#ddd" flex={1}>
                              | Por fase
                            </Typography>
                            <Typography>
                              {dayjs().format('MMMM YYYY')}
                            </Typography>
                          </Box>
                          <Box>
                            <Grid container>
                              <Grid item xs={12} md={4}>
                                <Box display="flex" alignItems="center" gap={1}>
                                  <Box
                                    bgcolor="#27ae60"
                                    width={10}
                                    height={10}
                                    borderRadius="50%"
                                  ></Box>
                                  <Typography fontSize={12}>{`Fase R ${(
                                    (activeEnergy.fase_r ?? 1) / status.divisor
                                  ).toFixed(2)} kWh`}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Box display="flex" alignItems="center" gap={1}>
                                  <Box
                                    bgcolor="#c0392b"
                                    width={10}
                                    height={10}
                                    borderRadius="50%"
                                  ></Box>
                                  <Typography fontSize={12}>{`Fase S ${(
                                    (activeEnergy.fase_s ?? 1) / status.divisor
                                  ).toFixed(2)} kWh`}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Box display="flex" alignItems="center" gap={1}>
                                  <Box
                                    bgcolor="#e67e22"
                                    width={10}
                                    height={10}
                                    borderRadius="50%"
                                  ></Box>
                                  <Typography fontSize={12}>{`Fase T ${(
                                    (activeEnergy.fase_t ?? 1) / status.divisor
                                  ).toFixed(2)} kWh`}</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Chart
                            chartType="PieChart"
                            data={[
                              ['Fase', 'kWh'],
                              [
                                `Fase R ${(
                                  (activeEnergy.fase_r ?? 1) / status.divisor
                                ).toFixed(2)} kWh`,
                                Math.abs(
                                  (activeEnergy.fase_r ?? 1) / status.divisor
                                ),
                              ],
                              [
                                `Fase S ${(
                                  (activeEnergy.fase_s ?? 1) / status.divisor
                                ).toFixed(2)} kWh`,
                                Math.abs(
                                  (activeEnergy.fase_s ?? 1) / status.divisor
                                ),
                              ],
                              [
                                `Fase T ${(
                                  (activeEnergy.fase_t ?? 1) / status.divisor
                                ).toFixed(2)} kWh`,
                                Math.abs(
                                  (activeEnergy.fase_t ?? 1) / status.divisor
                                ),
                              ],
                            ]}
                            options={{
                              title: '',
                              backgroundColor: 'transparent',
                              colors: ['#27ae60', '#c0392b', '#e67e22'],
                              chartArea: {
                                left: 20, // Ajusta el margen izquierdo
                                right: 20, // Ajusta el margen derecho
                                top: 50, // Ajusta el margen superior
                                bottom: 70, // Ajusta el margen inferior
                              },
                              legend: 'none' /* {
															position: 'left', // Mueve la leyenda al lado derecho
															alignment: 'center', // Centra la leyenda verticalmente
															textStyle: {
																color: 'white', // Cambia 'blue' por el color que desees
																fontSize: 10, // Puedes ajustar el tamaño de la fuente también
															},
														} */,
                            }}
                            width={'100%'}
                            height={'300px'}
                          />
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        sx={{ border: 1, p: 2, borderColor: '#2c3e50' }}
                      >
                        <Box display="flex" flexDirection="column" gap={2}>
                          <Box
                            display="flex"
                            alignItems="center"
                            justifyContent="start"
                            flexWrap="wrap"
                            gap={0}
                          >
                            <Typography fontWeight="700">
                              ENERGÍA REACTIVA MENSUAL
                            </Typography>
                            <Typography color="#ddd" flex={1}>
                              | Por fase
                            </Typography>
                            <Typography>
                              {dayjs().format('MMMM YYYY')}
                            </Typography>
                          </Box>
                          <Box>
                            <Grid container>
                              <Grid item xs={12} md={4}>
                                <Box display="flex" alignItems="center" gap={1}>
                                  <Box
                                    bgcolor="#27ae60"
                                    width={10}
                                    height={10}
                                    borderRadius="50%"
                                  ></Box>
                                  <Typography fontSize={12}>{`Fase R ${(
                                    (reactiveEnergy.fase_r ?? 1) /
                                    status.divisor
                                  ).toFixed(2)} kVARh`}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Box display="flex" alignItems="center" gap={1}>
                                  <Box
                                    bgcolor="#c0392b"
                                    width={10}
                                    height={10}
                                    borderRadius="50%"
                                  ></Box>
                                  <Typography fontSize={12}>{`Fase S ${(
                                    (reactiveEnergy.fase_s ?? 1) /
                                    status.divisor
                                  ).toFixed(2)} kVARh`}</Typography>
                                </Box>
                              </Grid>
                              <Grid item xs={12} md={4}>
                                <Box display="flex" alignItems="center" gap={1}>
                                  <Box
                                    bgcolor="#e67e22"
                                    width={10}
                                    height={10}
                                    borderRadius="50%"
                                  ></Box>
                                  <Typography fontSize={12}>{`Fase T ${(
                                    (reactiveEnergy.fase_t ?? 1) /
                                    status.divisor
                                  ).toFixed(2)} kVARh`}</Typography>
                                </Box>
                              </Grid>
                            </Grid>
                          </Box>
                          <Chart
                            chartType="PieChart"
                            data={[
                              ['Fase', 'kVARh'],
                              [
                                `Fase R ${(
                                  (reactiveEnergy.fase_r ?? 1) / status.divisor
                                ).toFixed(2)} kVARh`,
                                Math.abs(
                                  (reactiveEnergy.fase_r ?? 1) / status.divisor
                                ),
                              ],
                              [
                                `Fase S ${(
                                  (reactiveEnergy.fase_s ?? 1) / status.divisor
                                ).toFixed(2)} kVARh`,
                                Math.abs(
                                  (reactiveEnergy.fase_s ?? 1) / status.divisor
                                ),
                              ],
                              [
                                `Fase T ${(
                                  (reactiveEnergy.fase_t ?? 1) / status.divisor
                                ).toFixed(2)} kVARh`,
                                Math.abs(
                                  (reactiveEnergy.fase_t ?? 1) / status.divisor
                                ),
                              ],
                            ]}
                            options={{
                              title: '',
                              backgroundColor: 'transparent',
                              colors: ['#27ae60', '#c0392b', '#e67e22'],
                              chartArea: {
                                left: 20, // Ajusta el margen izquierdo
                                right: 20, // Ajusta el margen derecho
                                top: 50, // Ajusta el margen superior
                                bottom: 70, // Ajusta el margen inferior
                              },
                              legend: 'none' /* {
															position: 'left', // Mueve la leyenda al lado derecho
															alignment: 'center', // Centra la leyenda verticalmente
															textStyle: {
																color: 'white', // Cambia 'blue' por el color que desees
																fontSize: 10, // Puedes ajustar el tamaño de la fuente también
															},
														}, */,
                            }}
                            width={'100%'}
                            height={'300px'}
                          />
                        </Box>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Gráfica de consumo x año */}
                  <Grid
                    item
                    sx={{ border: 1, p: 2, borderColor: '#2c3e50' }}
                    xs={12}
                    md={6}
                  >
                    <Grid container gap={1}>
                      <Grid item xs={12}>
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="start"
                          gap={1}
                        >
                          <Typography fontWeight="700">Consumo</Typography>
                          <Typography color="#ddd" flex={1}>
                            | Este año
                          </Typography>
                          <Button
                            variant="text"
                            onClick={() => {
                              setCollapse({
                                ...collapse,
                                consume_year: !collapse.consume_year,
                              });
                            }}
                          >
                            {!collapse.consume_year && <RemoveIcon />}
                            {collapse.consume_year && <AddIcon />}
                          </Button>
                        </Box>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        style={{
                          display: collapse.consume_year ? 'none' : 'block',
                        }}
                      >
                        <Chart
                          chartType="ColumnChart"
                          width="100%"
                          height="300px"
                          data={[
                            ['Día', 'Consumo'],
                            ...consumptionReportYear.map((crm) => {
                              return [
                                crm.mes,
                                parseFloat((crm.acumulado_eadi).toFixed(2)),
                              ];
                            }),
                          ]}
                          options={{
                            vAxis: {
                              title: 'Potencia activa total (MkW)',
                              titleTextStyle: { color: '#FFF' }, // Cambia el color del título del eje vertical a blanco
                              textStyle: { color: '#FFF' }, // Cambia el color del texto del eje vertical a blanco
                              minValue: 0, // Hace que el eje vertical comience desde 0
                            },
                            hAxis: {
                              textStyle: { color: '#FFF', fontSize: 10 }, // Cambia el color del texto del eje horizontal a blanco
                              slantedText: true,
                              slantedTextAngle: 45,
                            },
                            legend: 'none',
                            backgroundColor: 'transparent',
                            chartArea: {
                              left: 70, // Ajusta el margen izquierdo
                              right: 20, // Ajusta el margen derecho
                              top: 50, // Ajusta el margen superior
                              bottom: 50, // Ajusta el margen inferior
                            },
                            colors: ['#27ae60'],
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Demanda de potencia */}
                  <Grid
                    item
                    sx={{
                      border: 1,
                      p: 2,
                      borderColor: '#2c3e50',
                      paddingBottom: 2,
                    }}
                    xs={12}
                    md={6}
                  >
                    <Grid item xs={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="start"
                        gap={1}
                      >
                        <Box
                          display="flex"
                          alignItems="center"
                          justifyContent="start"
                          gap={1}
                          flex={1}
                        >
                          <Typography fontWeight="700">
                            Demanda de potencia
                          </Typography>
                          <Typography color="#ddd">| Este mes</Typography>
                        </Box>

                        <Box display="flex" gap={2}>
                          <FormControl>
                            <InputLabel id="time_report">Tiempo</InputLabel>
                            <Select
                              labelId="time_report"
                              id="time_report_select"
                              value={reportTime}
                              label="Tiempo"
                              size="small"
                              onChange={(e) => {
                                setReportTime(
                                  (e.target.value as any) ?? 'month'
                                );
                              }}
                            >
                              <MenuItem value={'month'}>Mes</MenuItem>
                              <MenuItem value={'week'}>Semana</MenuItem>
                              <MenuItem value={'24hrs'}>24 hrs</MenuItem>
                            </Select>
                          </FormControl>
                          <Button
                            variant="text"
                            onClick={() => {
                              setCollapse({
                                ...collapse,
                                power_demand: !collapse.power_demand,
                              });
                            }}
                          >
                            {!collapse.power_demand && <RemoveIcon />}
                            {collapse.power_demand && <AddIcon />}
                          </Button>
                        </Box>
                      </Box>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: collapse.power_demand ? 'none' : 'block',
                        height: 300,
                      }}
                    >
                      <Line
                        data={{
                          labels: powerDemandReport.map((pd) => dayjs(pd.periodo).format('DD/MM HH:mm')),
                          datasets: [
                            {
                              label: 'Potencia Total',
                              data: powerDemandReport.map(
                                (pd) => pd.demanda_potencia
                              ), // Datos de Potencia Total
                              borderColor: '#8e44ad',
                              backgroundColor: 'rgba(142, 68, 173, 0.2)',
                              fill: false,
                            },
                            {
                              label: 'Potencia Fase R',
                              data: powerDemandReport.map((pd) => pd.pafa), // Datos de Potencia Fase R
                              borderColor: '#e74c3c',
                              backgroundColor: 'rgba(231, 76, 60, 0.2)',
                              fill: false,
                            },
                            {
                              label: 'Potencia Fase S',
                              data: powerDemandReport.map((pd) => pd.pafb), // Datos de Potencia Fase S
                              borderColor: '#3498db',
                              backgroundColor: 'rgba(52, 152, 219, 0.2)',
                              fill: false,
                            },
                            {
                              label: 'Potencia Fase T',
                              data: powerDemandReport.map((pd) => pd.pafc), // Datos de Potencia Fase T
                              borderColor: '#2ecc71',
                              backgroundColor: 'rgba(46, 204, 113, 0.2)',
                              fill: false,
                            },
                          ],
                        }}
                        options={{
                          responsive: true,
                          maintainAspectRatio: false,
                          elements: {
                            point: {
                              radius: 1,
                            },
                          },
                          scales: {
                            y: {
                              beginAtZero: true,
                              title: {
                                display: true,
                                text: 'Potencia activa total (kW)',
                                color: '#FFF',
                              },
                              ticks: {
                                color: '#FFF', // Cambia el color del texto del eje Y a blanco
                              },
                            },
                            x: {
                              ticks: {
                                display: true,
                                color: '#FFF', // Cambia el color del texto del eje X a blanco
                                maxRotation: 45, // Ajusta la inclinación de las etiquetas del eje X
                                minRotation: 45,
                              },
                            },
                          },
                          plugins: {
                            legend: {
                              position: 'top',
                              labels: {
                                color: '#FFF', // Cambia el color del texto de la leyenda a blanco
                              },
                            },
                            tooltip: {
                              enabled: true,
                            },
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </LocalizationProvider>
        </Box>
      </ZoomWrapper>
    </>
  );
};

export default Dashboard;
