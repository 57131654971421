import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Typography,
  useTheme,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { FC, useEffect, useState } from 'react';
import { Chart } from 'react-google-charts';
import dayjs from 'dayjs';
import 'dayjs/locale/es';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import http from '../../infrastructure/api/http';
import { useSelector } from 'react-redux';
import { selectUser } from '../../domain/store/session/selectors';
import { Center } from '../../domain/entities/Center';
import { UserAccess } from '../../shared/enums/user-access.enum';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';

dayjs.extend(isSameOrBefore);

type Variable = {
  id: number;
  name: string;
  isDefault: boolean;
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const Reports: FC = () => {
  const theme = useTheme();
  const [startAt, setStartAt] = useState<dayjs.Dayjs | null>(dayjs());
  const [endAt, setEndAt] = useState<dayjs.Dayjs | null>(dayjs());
  const [results, setResults] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [variables, setVariables] = useState<Variable[]>([]);
  const [centers, setCenters] = useState<Center[]>([]);
  const [variableSelected, setVariableSelected] = useState<number[]>([]);
  const [centerSelected, setCenterSelected] = useState<number | ''>('');
  const currentUser = useSelector(selectUser);

  const handlSearch = async () => {
    if (!startAt || !endAt) {
      alert('Debe seleccionar un rango de fechas');
      return;
    }

    if (endAt?.diff(startAt, 'days') > 15) {
      alert('El rango de fechas no puede ser mayor a 15 días');
      return;
    }

    if (centerSelected === '') {
      alert('Debe seleccionar un centro');
      return;
    }

    setIsLoading(true);
    setResults([]);
    try {
      const response = await http.get<any>(
        `/centers/${centerSelected}/reports/${startAt.toISOString()}/${endAt.toISOString()}/pdf`,
        { responseType: 'arraybuffer' }
      );

      const fileExtension = '.pdf';
      const fileName = `Reporte de ${startAt.format(
        'DD/MM/YYYY'
      )} a ${endAt.format('DD/MM/YYYY')}`;

			const blob = new Blob([response.data], { type: 'application/pdf' });
      FileSaver.saveAs(blob, fileName + fileExtension);
    } catch (error: any) {
      alert(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (currentUser) {
      (async () => {
        try {
          const response = await http.get<Variable[]>('/variables');
          setVariables(response.data);
        } catch (error: any) {
          alert(error.message);
        }
      })();
      (async () => {
        if (
          currentUser?.access === UserAccess.ENERPRY ||
          currentUser?.access === UserAccess.SUPER_USER
        ) {
          try {
            const response = await http.get<any>(`/centers`);
            setCenters(response.data);
          } catch (error: any) {
            alert(error.message);
          }
        } else {
          try {
            const response = await http.get<any>(`/users/${currentUser?.id}`);
            setCenters(response.data.centers);
          } catch (error: any) {
            alert(error.message);
          }
        }
      })();
    }
  }, [currentUser]);

  const handleChangeVariable = (
    event: SelectChangeEvent<typeof variableSelected>
  ) => {
    const {
      target: { value },
    } = event;
    setVariableSelected(value as number[]);
  };

  return (
    <Box sx={{ p: 2 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ mb: 5 }}>
          <Typography component="h1" variant="h5">
            Reportes
          </Typography>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                value={startAt}
                onChange={(date) => setStartAt(date)}
                label="Fecha de inicio"
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
            <DemoContainer components={['DateTimePicker']}>
              <DateTimePicker
                value={endAt}
                onChange={(date) => setEndAt(date)}
                label="Fecha final"
              />
            </DemoContainer>
          </LocalizationProvider>
        </Grid>
        <Grid item xs={12} md={6}>
          <FormControl fullWidth>
            <InputLabel id="center-label">Centro</InputLabel>
            <Select
              labelId="center-label"
              id="center-select"
              value={centerSelected}
              label="Centro"
              onChange={(value) =>
                setCenterSelected(value.target.value as number)
              }
            >
              {centers.map((center) => (
                <MenuItem key={center.id} value={center.id}>
                  {center.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} md={12}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handlSearch}
            disabled={isLoading}
            startIcon={
              isLoading ? <CircularProgress size={14} color="inherit" /> : <></>
            }
            sx={{ textTransform: 'none' }}
          >
            Generar PDF
          </Button>
        </Grid>
        {results.length > 0 && (
          <Grid item xs={12}>
            <Chart
              chartType="LineChart"
              width="100%"
              height="400px"
              data={results}
              options={{
                backgroundColor: theme.palette.background.paper,
                chart: {
                  title: 'Enerpry',
                },
                hAxis: {
                  textStyle: {
                    color: theme.palette.text.primary,
                  },
                },
                vAxis: {
                  textStyle: {
                    color: theme.palette.text.primary,
                  },
                },
                legend: {
                  textStyle: {
                    color: theme.palette.text.primary,
                  },
                },
                annotations: {
                  textStyle: {
                    color: theme.palette.text.primary,
                  },
                },
              }}
            />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default Reports;
